
























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里

@Component({
    components: {

    }
})
export default class openDialog extends Vue {
    public list: any[] = [];
    public audioDuration: number = 0;
    public fileList: any[] = [];
    public actionUrl: any = '';
    public formData: any = {
        filessrc: '',
    };
    public formLabelWidth: any = '140px';
    public showList: any = false;


    public rules: any = {
        channelId: [
            { required: true, message: '请选择', trigger: ['blur', 'change'] }],
        filessrc: [
            { required: true, message: '请上传', trigger: ['blur', 'change'] }]
    }
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public created(): void {
        this.formData.filessrc = '';
        this.fileList = [];
        // this.actionUrl = serverconfig.proxy["/api/application"].target + 'api/application/media/upload';
        this.actionUrl = document.location.origin + '/api/application/role/play/users/upload';
        // this.actionUrl = document.location.origin + '/api/application/media/upload';
        // this.actionUrl = "http://localhost:8080/api/application/media/upload";
    }
    public beforeAvatarUpload(files: { type: string; size: number; }) {
        // 文件类型进行判断
        console.log('files', files)
        var fileType = files.name.substring(files.name.lastIndexOf(".") + 1);
        const typeOne = fileType === "xlsx";
        const isLt20M = files.size / 1024 / 1024 < 20;
        if (!typeOne) {
            this.$message.error("上传文件格式错误!");
            this.fileList = [];
            this.audioDuration = 0;
            this.formData.filessrc = ''
            return false;
        } else {

        }
        this.audioDuration = 0;
        return typeOne
    }

    public async uploadClick(formName: any): Promise<void> {
        const addRef = (this.$refs.formData as any);
        // return;
        addRef.validate(async (valid: any) => {
            if (valid) {
                var data_ = {
                    'file': this.formData.filessrc
                };
                // 本地文件上传
                if (this.formData.filessrc != '' && this.formData.filessrc != null && this.formData.filessrc != undefined) {
                    const res = await this.statisticsApi.RPUpload(data_);
                    this.formData.filessrc = '';
                    this.fileList = [];
                    this.handleRemove()
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                    this.getChild();
                } else {
                    this.$message.error("请上传文件!");
                }

            }
        })

    }
    public async handleRemove() {
        this.fileList = [];
        this.formData.filessrc = '';

    }
    public async handlePreview(file: any) {
        console.log(file);
    }
    public async successUpload(response: any, file: any, fileList: any) {
        console.log('178', response)
        if (response.header.code == 200) {
             this.$message({
                message: '导入成功',
                type: 'success'
            });
            this.getChild();
            this.fileList = [];
            this.formData.filessrc = '';
        } else {
            this.$message(response.header.message);
            this.getChild();
            this.fileList = [];
            this.formData.filessrc = '';

        }
    }
    public async errorUpload(response: any, file: any, fileList: any) {
        console.log('error', response)
        if (response.header.code == 200) {
            this.getChild();
            this.fileList = [];
            this.formData.filessrc = '';
        } else {
            this.$message(response.header.message);

        }
    }

    // 去后缀名 验证是否正确
    public getfileName(name: any) {
        // var suffix = name.substring(name.lastIndexOf('.'));
        var suffix = name.substring(name.lastIndexOf('.') + 1);
        if (suffix == 'word' || suffix == 'excel' || suffix == 'ppt' || suffix == 'pdf' || suffix == 'doc' || suffix == 'pptx') {
            return true
        } else {
            return false
        }

    }
    @Emit('getChild')
    public getChild(): any {
        this.formData.filessrc = '';
        this.fileList = [];
        this.formData = {
            'agentId': '',
            'url': '',
            'filessrc': '',
        }
        return true
    }
    /**
     * -END- CALL SERVICE
     */
}
